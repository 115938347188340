// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.avatarColumn,
.optionsColumn {
  width: 40px;
}

.nameColumn {
  width: 30%;
}

.organizationsColumn {
  width: 70%;
}

.roleColumn {
  width: 50%;
}

.roleColumnWrapper {
  width: 100%;
}

.roleColumnContent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}

.lastLoginColumn {
  width: 20%;
}

.userName {
  font-weight: bold;
}
